// All Responsive Media Queries in a single file

@media screen and (max-width: 576px) {
    .main-content {
      max-width: 92%;
    }
  }
  
  @media screen and (max-width: 998px) {
  
    .star-systems {
      .pagination {
        display: table;
        li {
          display: inline-block;
        }
      }
      .table-content {
        .thead-dark {
          display: none;
        }
        .table td, .table th {
          display: block;
  
          &.xs-labels-header {
            background-color: #003e80;
            color: #FFF;
            text-align: center;
            border: none;
            i {
              display: inline-block;
            }
          }
          .xs-labels {
            display: inline-block;
            font-weight: bold;
          }
        }
      }
    }
  
  
  
    // Holder Panels for Smaller Devices
    .holder-panel{
      position: relative;
      margin: 0 auto;
  
      .systems-panel {
        flex: 0 0 100%;
        max-width: 100%;
      }
      
      .details-panel {
        flex: 0 0 100%;
        max-width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: fixed;
        z-index:5;
        background-color: #FFF;
        overflow-y: auto;
  
        &.hide-toolbar {
          display: none;
        }
  
        .details-holder {
          width: 95%;
        }
  
      }
  
    }
    // System Details
    .details-panel {
      .btn-danger {
        display: inline-block;
        margin-bottom: 1rem;
      }
    }
  
  }
  