// Basic Root Styles
body {
  background-color: #000;
  background: url(./assets/bg.jpg) 0 0 no-repeat;
  background-size: cover;
  font-size: 18px;
}

// Basic Content Styles
.main-content {
  background-color: rgba(255, 255, 255, 0.95);
  background-color: #FFF;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  box-shadow: #3e3e3e 0 0 50px;
  -moz-box-shadow: #3e3e3e 0 0 50px;
  -webkit-box-shadow: #3e3e3e 0 0 50px;
  margin: 2rem auto;
  min-height: 400px;
}

// Pagination Styles
.pagination {
  li {
    a {
      position: relative;
      display: block;
      padding: .5rem .75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6;
      cursor: pointer;
    }
    &.active {
      a {
        z-index: 3;
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
      }
    }

    &.next, &.previous {
      a {
        color: #007bff;
        background-color: #fff;
        outline: none;
      }

      &:focus {
        outline: none;
      }

      &.disabled { 
        a {
          color: #bcbcbc;
          pointer-events: none;
          cursor: auto;
          background-color: #fff;
          border-color: #dee2e6;
        }
      }
    }
  }
}

// General Footer Styles
.footer-text {
  .badge {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    font-size: inherit;
  }
}

// Star Systems Component Styles
.star-systems {
  min-height: 400px;
  
  .form-control {
    width: 100%;
    border: solid 1px #5a7792;
    background-color: aliceblue;
    padding: 1rem 1rem;
    color: #0051a2;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.2em;
    &:focus, &:active {
      background-color: #fff0f0;
    }
  }

  .table-content {
    
    .button-holder {
      min-width: 120px;
    }

    .table {
      border: solid 1px #5a7792;

      .thead-dark th {
        background-color: #003e80;
      }

      tr {
        &:nth-child(even) {
          background-color: rgba(0,0,0,.05);
        }
      }
      .xs-labels {
        display: none;
      }
      .xs-labels-header {
        i {
          display: none;
        }
      }
    }

    .similar-badges {
      .badge {
        padding: 0.5rem 1.1rem;
        margin: 0.2rem;
      }
    }
  }
}

// System Details Component Styles
.details-panel {
  .btn-danger {
    display: none;
  }
}

// System Details Component Styles
.system-details {
  
  .card-header {
    .nav-tabs .nav-link {
      padding: 0.7rem;
    }
  }

  .card-body {
    margin-bottom: 2rem;

    .badge {
      padding: 0.6rem 1.2rem;
      margin: 0.2rem;
      font-size: 1.1rem;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      cursor: default;

      &:hover {
        background-color: #0e6775;
      }
    }
  }
}

// Homepage Star Animation
.star-holder {
  min-height: 210px;
  
  .star-bounce {
    -webkit-animation: bounce 1.2s ease-in;
    -moz-animation: bounce 800ms ease-in;
    -o-animation: bounce 800ms ease-in;
    animation: bounce 1.2s ease-in;
  }
}